<template>
  <div class="accounts-sidebar">
    <div v-if="!loading" class="accounts-sidebar-list">
      <div
        v-for="list in lists"
        :key="list.uuid"
        class="accounts-sidebar-list-item"
        :class="{ selected: selected.uuid === list.uuid }"
        @click="() => $emit('select', list)"
      >
        {{ list.name }}
        <Button
          v-if="canWriteProspectingLists"
          icon="bin"
          type="grey"
          size="xs"
          :loading="removeLoading.includes(list.uuid)"
          class="accounts-sidebar-list-item-remove"
          @click.native.stop="() => $emit('remove', list)"
        />
      </div>
    </div>
    <div v-else class="accounts-sidebar-loading">
      <b-skeleton width="100%" height="2.5rem" />
      <b-skeleton width="100%" height="2.5rem" />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountsSidebar',
  components: { Button },
  props: {
    lists: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    removeLoading: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['canWriteProspectingLists'])
  }
}
</script>

<style lang="scss" scoped>
.accounts-sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(#000, 0.08);
  background: #f9f9fa;
  min-width: 20vw; // hack to prevent sidebar from being pushed in by accounts table being rendered

  &-list {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;

    &-item {
      padding: 0.75rem;
      margin: 0.25rem 0.5rem;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &:hover {
        background: rgba(#000, 0.02);

        & .accounts-sidebar-list-item-remove {
          opacity: 1;
        }
      }

      &.selected {
        background: rgba(#115efb, 0.08);
        color: $primary;

        &:hover {
          background: rgba(#115efb, 0.12);
        }
      }

      &-remove {
        margin-left: auto;
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
  }

  &-loading {
    display: flex;
    padding: 0.5rem;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }
}

::v-deep .b-skeleton {
  margin: 0 !important;
}
</style>
