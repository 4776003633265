var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accounts-list" },
    [
      _c("div", { staticClass: "accounts-list-header" }, [
        _vm.list
          ? _c(
              "div",
              { staticClass: "accounts-list-header-assignees" },
              [
                _c(
                  "p",
                  { staticClass: "accounts-list-header-assignees-title" },
                  [_vm._v(" Assigned to ")]
                ),
                _vm._l(_vm.list.assignees, function(assignee) {
                  return _c(
                    "div",
                    {
                      key: assignee.uuid,
                      staticClass: "accounts-list-header-assignees-item"
                    },
                    [
                      _c("Avatar", {
                        attrs: {
                          user: assignee,
                          size: "xs",
                          "fallback-icon": "company"
                        }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$umodel.full_name(assignee)) + " "
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "accounts-list-header-assignees loading" },
              [
                _c("b-skeleton", {
                  attrs: { width: "8rem", height: "2.5rem" }
                }),
                _c("b-skeleton", { attrs: { width: "8rem", height: "2.5rem" } })
              ],
              1
            ),
        _c(
          "div",
          { staticClass: "accounts-list-header-btns" },
          [
            _c("Button", {
              attrs: {
                text: "Export to CSV",
                disabled: _vm.loading,
                loading: _vm.exportLoading
              },
              on: { click: _vm.handleExport }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          ref: "accountstable",
          staticClass: "accounts-list-table",
          style: Object.assign({}, _vm.maxHeightStyle, _vm.maxWidthStyle)
        },
        [
          !_vm.listsLoading
            ? _c("Table", {
                attrs: {
                  items: _vm.paginatorData,
                  headers: _vm.headers,
                  sortable: false,
                  padding: "1rem"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var header = ref.header
                        var item = ref.item
                        return [
                          header.id === "name"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "accounts-list-table-item-name-wrapper"
                                },
                                [
                                  _c("Avatar", {
                                    attrs: {
                                      user: {
                                        avatar: item.organisation.icon,
                                        username: item.name
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "accounts-list-table-item-name-content"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "accounts-list-table-item-name-content-top"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(header.key(item)) + " "
                                          ),
                                          item.linkedin_url
                                            ? _c("img", {
                                                staticClass:
                                                  "accounts-list-table-item-name-content-top-linkedin",
                                                attrs: {
                                                  src: require("@/assets/logo/linkedin.svg"),
                                                  alt: ""
                                                },
                                                on: {
                                                  click: function() {
                                                    return _vm.gotoLinkedIn(
                                                      item
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      ),
                                      item.domain
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "accounts-list-table-item-name-content-domain",
                                              on: {
                                                click: function() {
                                                  return _vm.gotoDomain(item)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(item.domain))]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            : header.id === "warm_intro"
                            ? _c("WarmIntroductions", {
                                attrs: {
                                  profiles: item.organisation.profiles,
                                  "pitch-loading":
                                    _vm.gotoPitchLoading === item.uuid
                                },
                                on: {
                                  pitch: function(people) {
                                    return _vm.gotoPitch(item, people)
                                  }
                                }
                              })
                            : header.id === "actions"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "accounts-list-table-item-actions"
                                },
                                [
                                  _c("Button", {
                                    attrs: { text: "See enrichments" },
                                    on: {
                                      click: function() {
                                        return (_vm.enrichmentModal = item)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "right",
                                          fn: function() {
                                            return [
                                              _vm.list.fields.length !==
                                              item.fields.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accounts-list-table-item-actions-loading"
                                                    },
                                                    [
                                                      _c("span", [_vm._v("-")]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.fields.length
                                                          ) +
                                                          "/" +
                                                          _vm._s(
                                                            _vm.list.fields
                                                              .length
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("img", {
                                                        staticClass:
                                                          "accounts-list-table-item-actions-loading-icon",
                                                        attrs: {
                                                          src: require("@/assets/icons/spinner.svg"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("Button", {
                                    attrs: {
                                      text: "Pitch",
                                      type: "white",
                                      loading:
                                        _vm.gotoPitchLoading === item.uuid,
                                      disabled:
                                        !!_vm.gotoPitchLoading &&
                                        _vm.gotoPitchLoading !== item.uuid
                                    },
                                    on: {
                                      click: function() {
                                        return _vm.gotoPitch(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1397598971
                )
              })
            : _vm._e(),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "accounts-list-loading" },
                [_c("b-loading", { attrs: { "is-full-page": false } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      !!_vm.meetingCreateAccount
        ? _c("EditMeeting", {
            attrs: {
              visible: !!_vm.meetingCreateAccount,
              "force-account": _vm.meetingCreateAccount,
              "force-people": _vm.meetingCreatePeople
            },
            on: {
              created: _vm.handleMeetingCreated,
              close: _vm.closeMeetingCreate
            }
          })
        : _vm._e(),
      _vm.enrichmentModal
        ? _c("AccountInsightsModal", {
            attrs: { account: _vm.enrichmentModal, list: _vm.list },
            on: {
              close: function() {
                return (_vm.enrichmentModal = undefined)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }