var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: "", "can-cancel": ["outside", "escape"] },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "account-modal" }, [
        _c("div", { staticClass: "account-modal-header" }, [
          _c(
            "div",
            { staticClass: "account-modal-header-info" },
            [
              _c("Avatar", {
                attrs: {
                  user: {
                    avatar: _vm.organisation.icon || _vm.organisation.logo
                  },
                  "fallback-icon": "company"
                }
              }),
              _vm._v(" " + _vm._s(_vm.organisation.name) + " ")
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "account-modal-header-btns" },
            [
              _vm.organisation && _vm.organisation.linkedin_url
                ? _c(
                    "a",
                    { attrs: { href: _vm.organisation.linkedin_url } },
                    [
                      _c("Button", {
                        attrs: {
                          text: "LinkedIn",
                          icon: "external-bold",
                          type: "light",
                          size: "xs"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("Button", {
                attrs: { icon: "close", type: "grey", size: "xs" },
                on: { click: _vm.close }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "account-modal-content" }, [
          _c(
            "div",
            { staticClass: "account-modal-sidebar" },
            _vm._l(_vm.insights, function(insight, idx) {
              return _c(
                "b-tooltip",
                {
                  key: "account-insight-tab-" + idx,
                  attrs: {
                    active: !insight.value,
                    label: "Loading, please return later...",
                    position: idx ? "is-top" : "is-bottom",
                    type: "is-dark"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "account-modal-sidebar-item",
                      class: {
                        selected: _vm.selectedInsight.name === insight.name,
                        disabled: !insight.value
                      },
                      on: {
                        click: function() {
                          return _vm.selectInsight(insight)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(insight.name) + " "),
                      !insight.value
                        ? _c("img", {
                            staticClass: "account-modal-sidebar-item-na",
                            attrs: {
                              src: require("@/assets/icons/spinner.svg"),
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "account-modal-insight" }, [
            _vm.showSelected
              ? _c(
                  "div",
                  { staticClass: "account-modal-insight-block" },
                  [
                    _c(
                      "div",
                      { staticClass: "account-modal-insight-block-header" },
                      [
                        _c("img", {
                          staticClass:
                            "account-modal-insight-block-header-icon",
                          attrs: {
                            src: require("@/assets/icons/ai.svg"),
                            alt: ""
                          }
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "account-modal-insight-block-header-title"
                          },
                          [_vm._v(" " + _vm._s(_vm.selectedInsight.name) + " ")]
                        )
                      ]
                    ),
                    _c("MarkdownEdit", {
                      key: _vm.insightKey,
                      attrs: { value: _vm.selectedInsight.value }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }