<template>
  <b-modal active :can-cancel="['outside', 'escape']" @close="close">
    <div class="account-modal">
      <div class="account-modal-header">
        <div class="account-modal-header-info">
          <Avatar
            :user="{ avatar: organisation.icon || organisation.logo }"
            fallback-icon="company"
          />
          {{ organisation.name }}
        </div>
        <div class="account-modal-header-btns">
          <a
            v-if="organisation && organisation.linkedin_url"
            :href="organisation.linkedin_url"
          >
            <Button
              text="LinkedIn"
              icon="external-bold"
              type="light"
              size="xs"
            />
          </a>
          <Button icon="close" type="grey" size="xs" @click="close" />
        </div>
      </div>
      <div class="account-modal-content">
        <div class="account-modal-sidebar">
          <b-tooltip
            v-for="(insight, idx) in insights"
            :key="`account-insight-tab-${idx}`"
            :active="!insight.value"
            label="Loading, please return later..."
            :position="idx ? 'is-top' : 'is-bottom'"
            type="is-dark"
          >
            <div
              class="account-modal-sidebar-item"
              :class="{
                selected: selectedInsight.name === insight.name,
                disabled: !insight.value
              }"
              @click="() => selectInsight(insight)"
            >
              {{ insight.name }}
              <img
                v-if="!insight.value"
                src="@/assets/icons/spinner.svg"
                alt=""
                class="account-modal-sidebar-item-na"
              />
            </div>
          </b-tooltip>
        </div>
        <div class="account-modal-insight">
          <div v-if="showSelected" class="account-modal-insight-block">
            <div class="account-modal-insight-block-header">
              <img
                src="@/assets/icons/ai.svg"
                alt=""
                class="account-modal-insight-block-header-icon"
              />
              <p class="account-modal-insight-block-header-title">
                {{ selectedInsight.name }}
              </p>
            </div>
            <MarkdownEdit :key="insightKey" :value="selectedInsight.value" />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'
import MarkdownEdit from '@c/library/MarkdownEdit.vue'

export default {
  name: 'AccountInsightsModal',
  components: { Button, Avatar, MarkdownEdit },
  props: {
    account: {
      type: Object,
      default: () => ({})
    },
    list: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedInsight: {},
    insightKey: 0
  }),
  computed: {
    organisation() {
      return this.account?.organisation
    },
    insights() {
      return (this.list?.fields || []).map(f => ({
        ...f,
        ...((this.account?.fields || []).find(af => af.name === f.name) || {})
      }))
    },
    showSelected() {
      return this.selectedInsight?.name && this.selectedInsight?.value
    }
  },
  created() {
    const first = (this.insights || []).find(i => !!i.value)
    if (first) this.selectedInsight = first
  },
  methods: {
    close() {
      this.$emit('close')
    },
    selectInsight(insight) {
      this.selectedInsight = insight
      this.insightKey++
    }
  }
}
</script>

<style lang="scss" scoped>
.account-modal {
  background: white;
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.75rem;
      font-size: 1.25rem;
      font-weight: 700;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.75rem;
    }
  }

  &-content {
    display: flex;
    flex-flow: row nowrap;
    height: calc(75vh - 4.5rem);
  }

  &-sidebar {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.25rem;
    padding: 1.5rem;
    border-right: 1px solid rgba(#000, 0.08);
    background: #f9f9fa;
    border-bottom-left-radius: 8px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    &-item {
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &:hover {
        background: rgba(#000, 0.04);
      }

      &.selected {
        color: $primary;
        background: #ebf1ff;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }

      &-na {
        height: 1.2rem;
        margin-left: auto;
        filter: brightness(0) saturate(100%) invert(37%) sepia(5%)
          saturate(584%) hue-rotate(165deg) brightness(102%) contrast(84%);
        animation: spin 1s linear infinite;
      }
    }
  }

  &-insight {
    flex: 3;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1.5rem;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    &-block {
      background: #f1f2f3;
      border-radius: 4px;
      padding: 0.75rem;
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;

      &-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;

        &-icon {
          height: 1rem;
        }

        &-title {
          font-size: 0.875rem;
          font-weight: 600;
        }
      }

      &-content {
        font-size: 0.875rem;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
