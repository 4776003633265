var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accounts-sidebar" }, [
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "accounts-sidebar-list" },
          _vm._l(_vm.lists, function(list) {
            return _c(
              "div",
              {
                key: list.uuid,
                staticClass: "accounts-sidebar-list-item",
                class: { selected: _vm.selected.uuid === list.uuid },
                on: {
                  click: function() {
                    return _vm.$emit("select", list)
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(list.name) + " "),
                _vm.canWriteProspectingLists
                  ? _c("Button", {
                      staticClass: "accounts-sidebar-list-item-remove",
                      attrs: {
                        icon: "bin",
                        type: "grey",
                        size: "xs",
                        loading: _vm.removeLoading.includes(list.uuid)
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                          return function() {
                            return _vm.$emit("remove", list)
                          }.apply(null, arguments)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _c(
          "div",
          { staticClass: "accounts-sidebar-loading" },
          [
            _c("b-skeleton", { attrs: { width: "100%", height: "2.5rem" } }),
            _c("b-skeleton", { attrs: { width: "100%", height: "2.5rem" } })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }