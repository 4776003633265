<template>
  <div ref="accountspage" class="accounts" :style="maxHeightStyle">
    <AccountsSidebar
      :lists="lists"
      :selected="selected"
      :loading="loading"
      :remove-loading="removeLoading"
      @select="l => selectList(l.uuid)"
      @remove="handleRemove"
    />
    <AccountsList :list="selected" :lists-loading="loading" />
  </div>
</template>

<script>
import {
  getProspectsLists,
  removeProspectsList
} from '@/services/prospectingService'
import AccountsSidebar from '@/views-v2/accounts/AccountsSidebar.vue'
import AccountsList from '@/views-v2/accounts/AccountsList.vue'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Accounts',
  components: {
    AccountsSidebar,
    AccountsList
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    lists: [],
    loading: false,
    removeLoading: [],
    selected: undefined
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'canReadProspectingLists'])
  },
  created() {
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
    if (!this.canReadProspectingLists) {
      this.$router.push({ name: 'home' })
    }

    this.getLists()
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    getMaxHeightElement() {
      return this.$refs.accountspage
    },
    selectList(uuid) {
      this.selected = this.lists.find(l => l.uuid === uuid) || this.lists[0]
      if (this.selected?.uuid !== this.$route.query.list)
        this.$router.push({
          name: 'accounts',
          params: {
            workspace_id: this.$route.params.workspace_id
          },
          query: {
            list: this.selected?.uuid
          }
        })
    },
    async getLists() {
      try {
        this.loading = true
        this.lists = await getProspectsLists({
          workspace_id: this.$route.params.workspace_id
        })
        this.selectList(this.$route.query?.list || this.lists[0]?.uuid)
      } catch (e) {
        this.$console.debug(e)
        this.$toast.error(e, 'loading prospects lists')
      } finally {
        this.loading = false
      }
    },
    async handleRemove(list) {
      this.removeLoading.push(list.uuid)
      try {
        await removeProspectsList({
          workspace_id: this.$route.params.workspace_id,
          list_id: list.uuid
        })
        this.lists = this.lists.filter(l => l.uuid !== list.uuid)
        if (list.uuid === this.selected.uuid)
          this.selectList(this.lists[0]?.uuid)
      } catch (e) {
        this.$console.debug(e)
        this.$toast.error(e, 'removing prospects list')
      } finally {
        this.removeLoading = this.removeLoading.filter(
          uuid => uuid !== list.uuid
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accounts {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
</style>
