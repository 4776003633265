<template>
  <div class="accounts-list">
    <div class="accounts-list-header">
      <div v-if="list" class="accounts-list-header-assignees">
        <p class="accounts-list-header-assignees-title">
          Assigned to
        </p>
        <div
          v-for="assignee in list.assignees"
          :key="assignee.uuid"
          class="accounts-list-header-assignees-item"
        >
          <Avatar :user="assignee" size="xs" fallback-icon="company" />
          {{ $umodel.full_name(assignee) }}
        </div>
      </div>
      <div v-else class="accounts-list-header-assignees loading">
        <b-skeleton width="8rem" height="2.5rem" />
        <b-skeleton width="8rem" height="2.5rem" />
      </div>
      <div class="accounts-list-header-btns">
        <Button
          text="Export to CSV"
          :disabled="loading"
          :loading="exportLoading"
          @click="handleExport"
        />
      </div>
    </div>
    <div
      ref="accountstable"
      class="accounts-list-table"
      :style="{ ...maxHeightStyle, ...maxWidthStyle }"
    >
      <Table
        v-if="!listsLoading"
        :items="paginatorData"
        :headers="headers"
        :sortable="false"
        padding="1rem"
      >
        <template #item="{ header, item }">
          <div
            v-if="header.id === 'name'"
            class="accounts-list-table-item-name-wrapper"
          >
            <Avatar
              :user="{ avatar: item.organisation.icon, username: item.name }"
            />
            <div class="accounts-list-table-item-name-content">
              <div class="accounts-list-table-item-name-content-top">
                {{ header.key(item) }}
                <img
                  v-if="item.linkedin_url"
                  src="@/assets/logo/linkedin.svg"
                  alt=""
                  class="accounts-list-table-item-name-content-top-linkedin"
                  @click="() => gotoLinkedIn(item)"
                />
              </div>
              <span
                v-if="item.domain"
                class="accounts-list-table-item-name-content-domain"
                @click="() => gotoDomain(item)"
                >{{ item.domain }}</span
              >
            </div>
          </div>
          <WarmIntroductions
            v-else-if="header.id === 'warm_intro'"
            :profiles="item.organisation.profiles"
            :pitch-loading="gotoPitchLoading === item.uuid"
            @pitch="people => gotoPitch(item, people)"
          />
          <div
            v-else-if="header.id === 'actions'"
            class="accounts-list-table-item-actions"
          >
            <Button
              text="See enrichments"
              @click="() => (enrichmentModal = item)"
            >
              <template #right>
                <div
                  v-if="list.fields.length !== item.fields.length"
                  class="accounts-list-table-item-actions-loading"
                >
                  <span>-</span>
                  {{ item.fields.length }}/{{ list.fields.length }}
                  <img
                    src="@/assets/icons/spinner.svg"
                    alt=""
                    class="accounts-list-table-item-actions-loading-icon"
                  />
                </div>
              </template>
            </Button>
            <Button
              text="Pitch"
              type="white"
              :loading="gotoPitchLoading === item.uuid"
              :disabled="!!gotoPitchLoading && gotoPitchLoading !== item.uuid"
              @click="() => gotoPitch(item)"
            />
          </div>
        </template>
      </Table>
      <div v-if="loading" class="accounts-list-loading">
        <b-loading :is-full-page="false" />
      </div>
    </div>
    <EditMeeting
      v-if="!!meetingCreateAccount"
      :visible="!!meetingCreateAccount"
      :force-account="meetingCreateAccount"
      :force-people="meetingCreatePeople"
      @created="handleMeetingCreated"
      @close="closeMeetingCreate"
    />
    <AccountInsightsModal
      v-if="enrichmentModal"
      :account="enrichmentModal"
      :list="list"
      @close="() => (enrichmentModal = undefined)"
    />
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import { MaxWidthMixin } from '@/mixins/MaxWidthMixin'
import { getProspectAccounts } from '@/services/prospectingService'

import Table from '@c/library/Table.vue'
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import WarmIntroductions from '@/views-v2/prospecting/WarmIntroductions.vue'
import EditMeeting from '@/views-v2/meeting/EditMeeting.vue'
import AccountInsightsModal from '@/views-v2/accounts/AccountInsightsModal.vue'

export default {
  name: 'AccountsList',
  mixins: [PaginatorConsumer, MaxHeightMixin, MaxWidthMixin],
  components: {
    Avatar,
    Table,
    Button,
    EditMeeting,
    WarmIntroductions,
    AccountInsightsModal
  },
  props: {
    list: {
      type: Object,
      default: undefined
    },
    listsLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    exportLoading: false,
    gotoPitchLoading: '',
    enrichmentModal: undefined,
    meetingCreateAccount: undefined,
    meetingCreatePeople: [],
    scrollType: ''
  }),
  computed: {
    loading() {
      return this.consumptionLoading || this.listsLoading
    },
    headers() {
      return [
        {
          id: 'name',
          title: 'Name',
          key: p => p.name
        },
        {
          id: 'headquarters',
          title: 'Headquarters',
          key: p => p.organisation.headquarters || 'Unknown'
        },
        {
          id: 'employee_count',
          title: 'Employees',
          key: p => p.organisation.employee_count || 'Unknown'
        },
        {
          id: 'industry',
          title: 'Industry',
          key: p => p.organisation.industry?.name || 'Unknown'
        },
        {
          id: 'warm_intro',
          title: 'Warm introductions',
          key: p => (p.organisation.profiles?.length ? 'Yes' : 'No')
        },
        {
          id: 'actions',
          title: '',
          key: () => ''
        }
      ]
    }
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        if (newVal?.uuid !== oldVal?.uuid)
          this[oldVal ? 'resetPaginatorConsumer' : 'setupPaginatorConsumer']()
      },
      deep: true
    }
  },
  mounted() {
    this.initInfiniteScroll()
  },
  methods: {
    initInfiniteScroll() {
      this.$refs.accountstable.addEventListener(
        'scroll',
        () => this.handleScroll('scroll')
      )
      this.$refs.accountstable.addEventListener(
        'wheel',
        () => this.handleScroll('wheel')
      )
    },
    handleScroll(type) {
      if (!this.scrollType) this.scrollType = type
      if (this.scrollType !== type) return
      if (
        this.$refs.accountstable &&
        !(this.consumptionLoading || this.isDone) &&
        this.$refs.accountstable.scrollHeight -
          this.$refs.accountstable.offsetHeight -
          this.$refs.accountstable.scrollTop <
          window.innerHeight / 6.66
      )
        this.loadPage()
    },
    getMaxHeightElement() {
      return this.$refs.accountstable
    },
    getMaxWidthElement() {
      return this.$refs.accountstable
    },
    getPaginator() {
      return getProspectAccounts({
        workspace_id: this.$route.params.workspace_id,
        list_id: this.list.uuid
      })
    },
    async setupPaginatorConsumer() {
      if (!this.list) return
      try {
        this.loadingError = false
        if (this.consumptionLoading) return
        this.consumptionLoading = true
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
        this.consumptionLoading = false
      } catch (e) {
        this.$console.debug('Error loading search results', e)
        this.$toast.error(e, 'loading search results')
        this.consumptionLoading = false
        this.searchSessionStarted = false
      }
    },
    async handleExport() {
      try {
        this.exportLoading = true
        const res = await getProspectAccounts({
          workspace_id: this.$route.params.workspace_id,
          list_id: this.list.uuid,
          export_filetype: 'csv',
          paginated: false
        })
        const blob = new Blob([res], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        const m = this.$moment(new Date())
        a.download = `uman_accounts_${m.format('DD-MM-YYYY-HH-mm-ss')}.csv`
        a.click()
        this.exportLoading = false
      } catch (e) {
        this.$console.debug('Error exporting accounts', e)
        this.$toast.error(e, 'exporting accounts')
      } finally {
        this.exportLoading = false
      }
    },
    async gotoPitch(item, people = []) {
      const audience = people.map(p => ({
        avatar: p.avatar,
        name: this.$umodel.full_name(p),
        linkedin_url: p.url.replaceAll('www.', ''),
        role: p.role
      }))
      this.meetingCreatePeople = audience
      this.meetingCreateAccount = item
    },
    closeMeetingCreate() {
      this.meetingCreateAccount = undefined
      this.meetingCreatePeople = []
    },
    async handleMeetingCreated({ meeting, templates, settings }) {
      this.closeMeetingCreate()
      localStorage.setItem('meetingOutputTemplates', JSON.stringify(templates))
      let props = localStorage.getItem('meetingOutputProps')
      props = props ? JSON.parse(props) : {}
      localStorage.setItem(
        'meetingOutputProps',
        JSON.stringify({ ...props, ...settings })
      )
      this.$router.push({
        name: 'meeting-outputs',
        params: {
          workspace_id: this.$route.params.workspace_id,
          meeting_id: meeting.uuid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.accounts-list {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    padding: 1rem 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-assignees {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
      align-items: center;

      &.loading {
        flex-flow: row nowrap;
      }

      &-title {
        font-weight: 700;
      }

      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
    }
  }

  &-table {
    overflow: auto;

    &-item {
      &-name {
        &-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 1rem;
          font-size: 1.15rem;
          font-weight: 600;
          width: 20rem;
        }

        &-content {
          display: flex;
          flex-flow: column nowrap;
          &-top {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 0.5rem;

            &-linkedin {
              height: 1.1rem;
              cursor: pointer;
            }
          }

          &-domain {
            cursor: pointer;
            color: $primary;
            font-weight: 300;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &-domain {
        cursor: pointer;
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }

      &-linkedin {
        cursor: pointer;
      }

      &-actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        width: 100%;
        justify-content: flex-end;

        &-loading {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;
          color: white;

          &-icon {
            height: 0.9rem;
            animation: spin 1s linear infinite;
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

::v-deep .b-skeleton {
  margin: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
