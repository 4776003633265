var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "accountspage", staticClass: "accounts", style: _vm.maxHeightStyle },
    [
      _c("AccountsSidebar", {
        attrs: {
          lists: _vm.lists,
          selected: _vm.selected,
          loading: _vm.loading,
          "remove-loading": _vm.removeLoading
        },
        on: {
          select: function(l) {
            return _vm.selectList(l.uuid)
          },
          remove: _vm.handleRemove
        }
      }),
      _c("AccountsList", {
        attrs: { list: _vm.selected, "lists-loading": _vm.loading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }